import React from "react";
import { Box, Text, Heading, Button } from "rebass/styled-components";
import Link from "../components/UI/Link";
import Page from "../templates/Page";
import { useStaticQuery, graphql } from "gatsby";
import HeaderPage from "../components/UI/HeaderPage";
export default () => {
  const { markdownRemark } = useStaticQuery(
    graphql`
      query {
        markdownRemark(frontmatter: { title: { eq: "siteinfos" } }) {
          frontmatter {
            page404 {
              title
              text
            }
          }
        }
      }
    `
  );
  const siteinfos = markdownRemark.frontmatter || {};
  return (
    <Page title={siteinfos.page404.title}>
      <HeaderPage title={siteinfos.page404.title} />
      <Box p={5}>
        <Text py={5} textAlign="center" fontSize="80px">
          404
        </Text>
        <Heading py={5} textAlign="center">
          {siteinfos.page404.title}
        </Heading>
        <Text textAlign="center">{siteinfos.page404.text}</Text>
        <Box py={5} textAlign="center">
          <Link href="/">
            <Button>Retour à l&apos;accueil</Button>
          </Link>
        </Box>
      </Box>
    </Page>
  );
};
